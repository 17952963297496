/**
 * Created by edlc on 1/3/17.
 * jQuery widget for collapsible nav items with css dependant transforms and
 *  transitions (good for both mobile and desktop).
 * @class $.edlc.edlcCollapsibleNav
 * @requires ./sass/edlc-collapsible-nav.scss
 * @requires jquery - JQuery library.
 * @requires jquery-ui.widget - JQuery Ui Widget factory.
 * @requires jquery-ui.Widget - JQuery Ui Widget prototype.
 * @requires es5+
 */
(function () {

    'use strict';

    const reduce = function (arrayLike, callback, aggregator, context) {
            return Array.prototype.reduce.call(arrayLike, callback, aggregator, context);
        },

        getStoredCollapseHeight = function ($elm) {
            return parseInt($elm.attr('data-collapsed-height'), 10) || 0;
        },

        noop = function () {},

        selectorSeparator = ' ';

    $.widget('edlc.edlcCollapsibleNav', {

        options: {
            highestListItemZIndex: 998,
            scrollTopSpeed: 1000,
            defaultOwnClassName: 'edlc-collapsible-nav',
            defaultOwnSelector: '.edlc-collapsible-nav',
            togglableListItemSelector: 'li.togglable',
            togglableListItemClassName: 'togglable',
            toggleBtnSelector: 'a + a',
            itemsWrapperSelector: '> ul',
            itemSelector: '> li',
            activeSelector: '.active',
            itemLinkWrapperSelector: '> div:first-child',
            activeClassName: 'active',
            itemCollapsedHeightAttr: 'data-collapsed-height',
            eventToToggleOn: 'touchend click',
            eventsSeparator: selectorSeparator,
            touchEndEventName: 'touchend',
            onItemToggle: noop
        },

        refresh: function () {
            const ops = this.options;
            this._removeEventListeners(ops)
                ._addUlAndLiZIndices(this.element, ops.highestListItemZIndex, ops)
                ._setCollapsedHeights(this.element, ops)
                ._markTogglables(this.element, ops)
                ._addEventListeners(ops);
        },

        destroy: function () {
            this._removeEventListeners(this.options);
        },

        _create: function () {
            const self = this,
                ops = self.options;

            // Toggle listener
            self._onToggleBtnListener = function (e) {
                if (ops.eventToToggleOn.indexOf(ops.eventsSeparator) > 0 &&
                    e.type && e.type === ops.touchEndEventName) {
                    e.preventDefault();
                }
                const $this = $(this),
                    $elm = $this.closest(ops.togglableListItemSelector),
                    collapsedHeight = getStoredCollapseHeight($elm),
                    activeClassName = ops.activeClassName;
                if ($elm.hasClass(activeClassName)) {
                    if (collapsedHeight) {
                        $elm.height(collapsedHeight)
                    }
                    $elm.removeClass(activeClassName);
                }
                else if (!$elm.hasClass(ops.activeClassName)) {
                    const expandedHeight = self._getExpandHeight($elm, ops);
                    $elm.height(expandedHeight);
                    $elm.addClass(activeClassName);
                }
                self._closeSiblingTogglables($elm, collapsedHeight, ops);
                self._updateParentHeights($elm, ops);
                ops.onItemToggle.call(this, e);
            };
        },

        _init: function () {
            this.refresh();
        },

        _addUlAndLiZIndices: function ($startingUl, startZIndex, ops) {
            const self = this,
                zIndexKey = 'z-index';

            if ($startingUl.length === 0) {
                return self;
            }

            // Add z-indices to passed in elements
            $startingUl.each(function (ind, elm) {
                const $elm = $(elm);
                $elm.css(zIndexKey, startZIndex--);
            });

            // Add z-indices for direct 'li' tags
            $startingUl.find(ops.itemSelector).each(function (index, li) {

                // Get 'li' tag and direct 'ul' tags
                const $li = $(li),
                    $ul = $li.find(ops.itemsWrapperSelector);

                $li.find(ops.itemLinkWrapperSelector).css(zIndexKey, startZIndex--);

                // Add 'li' z-index
                $li.css(zIndexKey, startZIndex--);

                // Add direct children z-indices
                self._addUlAndLiZIndices($ul, startZIndex, ops);
            });

            return self;
        },

        _setCollapsedHeights: function ($startingUl, ops) {
            const self = this;
            $startingUl.find(ops.itemSelector).each(function (ind, li) {
                const $li = $(li),
                    $ul = $li.find(ops.itemsWrapperSelector);
                var collapsedHeight;

                if ($ul.length > 0) {
                    const displayPropName = 'display',
                        originalUlDisplay = $ul.css(displayPropName);
                    $ul.css(displayPropName, 'none');
                    collapsedHeight = $li.height();
                    $ul.css(displayPropName, originalUlDisplay);
                    self._setCollapsedHeights($ul, ops);
                }
                else {
                    collapsedHeight = $li.height();
                }

                $li.attr(ops.itemCollapsedHeightAttr, collapsedHeight)
                    .height(collapsedHeight);
            });

            return this;
        },

        _markTogglables: function ($startingUl, ops) {
            const self = this;

            $startingUl.find(ops.itemSelector).each(function (ind, li) {
                const $li = $(li),
                    $ul = $li.find(ops.itemsWrapperSelector);

                if ($ul.length > 0) {
                 $li.addClass(self.options.togglableListItemClassName);
                 self._markTogglables($ul, ops);
                }
            });

            return this;
        },

        _getExpandHeight: function ($li, ops) {
            const self = this,
                $items = $li.find(ops.itemsWrapperSelector + selectorSeparator + ops.itemSelector);
            return reduce($items, function (agg, elm) {
                    const $elm = $(elm);
                    return agg + ($elm.hasClass(ops.activeClassName) ?
                            self._getExpandHeight($elm, ops) :
                            getStoredCollapseHeight($elm));
                }, getStoredCollapseHeight($li));
        },

        _updateParentHeights: function ($li, ops) {
            if ($li.parent().hasClass(ops.defaultOwnClassName)) {
                return;
            }
            const $parentLi = $li.parent().closest(ops.togglableListItemSelector);
            $parentLi.height(this._getExpandHeight($parentLi, ops));
            this._updateParentHeights($parentLi, ops);
        },

        _closeSiblingTogglables: function ($togglableLi, collapsedHeight, ops) {
            $togglableLi.siblings().each(function (ind, elm) {
                const $elm = $(elm);
                if ($elm.hasClass(ops.activeClassName) && collapsedHeight) {
                    $elm.height(getStoredCollapseHeight($elm))
                        .removeClass(ops.activeClassName);
                }
                $elm.removeClass(ops.activeClassName);
            });
        },

        _addEventListeners: function (ops) {
            const self = this,
                toggleBtnSelector = ops.togglableListItemSelector + selectorSeparator + ops.toggleBtnSelector;
            this.element.on(ops.eventToToggleOn, toggleBtnSelector, self._onToggleBtnListener);
            return this;
        },

        _removeEventListeners: function (ops) {
            const self = this,
                toggleBtnSelector = ops.togglableListItemSelector + selectorSeparator + ops.toggleBtnSelector;
            this.element.off(ops.eventToToggleOn, toggleBtnSelector, self._onToggleBtnListener);
            return this;
        }
    });

}());
